import React from 'react'
import { Alert } from 'react-bootstrap'
import { useImportar } from '../context/Importar'

export default function AppAlert(props) {
  const { setShowAlert } = useImportar()
  const handleDismiss = () => setShowAlert(false);

  return (
  <Alert show={props.showAlert} variant={props.alertVariant} onClose={handleDismiss} dismissible>
    {props.alertMessage}
  </Alert>
  )
}
