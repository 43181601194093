import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import 'whatwg-fetch'
import Table from '../components/Table'

import { isMobile } from 'react-device-detect'

export default function PlanoConta() {
  const { user } = useSelector((state) => state.auth, shallowEqual)

  const tableTemplate = {
    apiURL: '/api/v1/planoconta',
    getParams: {
      grupoempresa_id: user.grupoempresa,
      empresa_id: user.empresa
    },
    postParams: {
      grupoempresa_id: user.grupoempresa,
      empresa_id: user.empresa
    },
    DataGrid: {
      showBorders: true,
      repaintChangesOnly: true,
      onInitNewRow: (e) => {
        e.data.ativo = true
      }
    },
    FilterRow: { visible: false },
    HeaderFilter: { visible: false },

    SearchPanel: { visible: true, width: 240, placeholder: 'Pesquisar...' },

    Paging: { defaultPageSize: 10 },

    Pager: {
      showPageSizeSelector: true,
      allowedPageSizes: [5, 10, 20],
      showInfo: true,
      infoText: 'Página {0} de {1} ({2} registros)'
    },

    Editing: {
      refreshMode: 'full',
      mode: 'popup',
      allowAdding: true,
      allowDeleting: true,
      allowUpdating: true,
      Texts: {
        editRow: 'Alterar',
        addRow: 'Incluir',
        deleteRow: 'Excluir',
        saveRowChanges: 'Salvar',
        cancelRowChanges: 'Cancelar',
        confirmDeleteMessage: 'Tem certeza que deseja excluir esse registro?'
      },
      Popup: {
        title: 'Cadastro de Plano de Conta',
        showTitle: true,
        width: 700,
        height: 525
      }
    },
    Columns: [
      {
        dataField: 'id',
        dataType: 'number',
        caption: 'ID',
        visible: isMobile ? false : true,
        formItem: {
          visible: false
        },
        allowEditing: false
      },
      {
        dataField: 'descricao',
        dataType: 'string',
        caption: 'Descrição',
        visible: isMobile ? false : true,
        formItem: { visible: true },
        allowEditing: true,

        RequiredRule: {
          message: 'Preenchimento Obrigatório'
        },

        StringLengthRule: {
          message: 'Descrição precisa ter 2 a 60 caracteres',
          min: 2,
          max: 60
        }
      },
      {
        dataField: 'identificador',
        dataType: 'string',
        caption: 'Identificador',
        allowEditing: true,

        RequiredRule: {
          message: 'Preenchimento Obrigatório'
        },

        StringLengthRule: {
          message: 'Identificador precisa ter 1 a 20 caracteres',
          min: 1,
          max: 20
        }
      },
      {
        dataField: 'reduzida',
        dataType: 'string',
        caption: 'Reduzida',
        allowEditing: true,

        RequiredRule: {
          message: 'Preenchimento Obrigatório'
        }
      },
      {
        dataField: 'ativo',
        dataType: 'boolean',
        caption: 'Ativo',
        visible: isMobile ? false : true,
        formItem: { visible: true },
        allowEditing: true
      }
    ]
  }

  return (
    <React.Fragment>
      <Table tableTemplate={tableTemplate} />
    </React.Fragment>
  )
}
