import React, { createContext, useContext, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'

const ImportarContext = createContext()

export default function ImportarProvider({ children }) {
  const user = useSelector((state) => state.auth.user, shallowEqual)
  const [apiURL, setApiURL] = useState('/api/v1/empresa')
  const [uploadURL, setUploadURL] = useState('/api/v1/importar')
  const [empresa, setEmpresa] = useState(0)
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [alertVariant, setAlertVariant] = useState('')
  const [grupoEmpresa, setGrupoEmpresa] = useState(user.grupoempresa)

  return (
    <ImportarContext.Provider
      value={{
        apiURL,
        setApiURL,
        uploadURL,
        setUploadURL,
        empresa,
        setEmpresa,
        showAlert,
        setShowAlert,
        alertMessage,
        setAlertMessage,
        alertVariant,
        setAlertVariant,
        grupoEmpresa,
        setGrupoEmpresa
      }}
    >
      {children}
    </ImportarContext.Provider>
  )
}

export function useImportar() {
  const context = useContext(ImportarContext)
  if (!context) throw new Error('useImportar não pode ser utilizado')
  const {
    apiURL,
    setApiURL,
    uploadURL,
    setUploadURL,
    empresa,
    setEmpresa,
    showAlert,
    setShowAlert,
    alertMessage,
    setAlertMessage,
    alertVariant,
    setAlertVariant,
    grupoEmpresa,
    setGrupoEmpresa
  } = context

  return {
    apiURL,
    setApiURL,
    uploadURL,
    setUploadURL,
    empresa,
    setEmpresa,
    showAlert,
    setShowAlert,
    alertMessage,
    setAlertMessage,
    alertVariant,
    setAlertVariant,
    grupoEmpresa,
    setGrupoEmpresa
  }
}
