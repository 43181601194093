/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react'
import { useLocation } from 'react-router'
import { NavLink } from 'react-router-dom'
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl, checkIsActive } from '../../../../_helpers'
import { shallowEqual, useSelector } from 'react-redux'

export function AsideMenuList({ layoutProps }) {
  const location = useLocation()
  const { user } = useSelector((state) => state.auth, shallowEqual)

  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && 'menu-item-active'} menu-item-open `
      : ''
  }

  return (
    <>
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        <li className={`menu-item ${getMenuItemActive('/dashboard', false)}`}>
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Design/Layers.svg')} />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>

        {/*begin::1 Level*/}
        {/*
        <li className={`menu-item ${getMenuItemActive('/builder', false)}`}>
          <NavLink className="menu-link" to="/operacao">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl('/media/svg/icons/Shopping/Calculator.svg')}
              />
            </span>
            <span className="menu-text">Operações</span>
          </NavLink>
        </li>
        */}

        {user.perfil === 'Master' ? (
          <li className={`menu-item ${getMenuItemActive('/importar', false)}`}>
            <NavLink className="menu-link" to="/Importar">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl('/media/svg/icons/Files/Import.svg')} />
              </span>
              <span className="menu-text">Importar Balancete</span>
            </NavLink>
          </li>
        ) : null}

        <li className={`menu-item ${getMenuItemActive('/pagamentos', false)}`}>
          <NavLink className="menu-link" to="/Pagamentos">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl('/media/svg/icons/Shopping/Calculator.svg')}
              />
            </span>
            <span className="menu-text">Pagamentos</span>
          </NavLink>
        </li>

        <li
          className={`menu-item ${getMenuItemActive('/recebimentos', false)}`}
        >
          <NavLink className="menu-link" to="/Recebimentos">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl('/media/svg/icons/Shopping/Wallet3.svg')}
              />
            </span>
            <span className="menu-text">Recebimentos</span>
          </NavLink>
        </li>

        {user.perfil === 'Master' || user.perfil === 'Administrador' ? (
          <li className="menu-section ">
            <h4 className="menu-text">Cadastros</h4>
            <i className="menu-icon flaticon-more-v2"></i>
          </li>
        ) : null}

        {/*         {user.perfil === 'Master' ? (
          <li className={`menu-item ${getMenuItemActive('/banco', false)}`}>
            <NavLink className="menu-link" to="/Banco">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl('/media/svg/icons/Shopping/Dollar.svg')}
                />
              </span>
              <span className="menu-text">Bancos</span>
            </NavLink>
          </li>
        ) : null}

        {user.perfil === 'Master' ? (
          <li
            className={`menu-item ${getMenuItemActive(
              '/centroresultado',
              false
            )}`}
          >
            <NavLink className="menu-link" to="/CentroResultado">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl('/media/svg/icons/General/Clipboard.svg')}
                />
              </span>
              <span className="menu-text">Centro de Resultado</span>
            </NavLink>
          </li>
        ) : null} */}

        {user.perfil === 'Master' ? (
          <li className={`menu-item ${getMenuItemActive('/empresa', false)}`}>
            <NavLink className="menu-link" to="/Empresa">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl(
                    '/media/svg/icons/Communication/Group.svg'
                  )}
                />
              </span>
              <span className="menu-text">Empresas</span>
            </NavLink>
          </li>
        ) : null}

        {user.perfil === 'Master' ? (
          <li
            className={`menu-item ${getMenuItemActive('/grupoempresa', false)}`}
          >
            <NavLink className="menu-link" to="/GrupoEmpresa">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl('/media/svg/icons/Home/Building.svg')}
                />
              </span>
              <span className="menu-text">Grupo Empresarial</span>
            </NavLink>
          </li>
        ) : null}

        {user.perfil === 'Master' ? (
          <li
            className={`menu-item ${getMenuItemActive('/planoconta', false)}`}
          >
            <NavLink className="menu-link" to="/PlanoConta">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl('/media/svg/icons/Files/File.svg')} />
              </span>
              <span className="menu-text">Plano de Conta</span>
            </NavLink>
          </li>
        ) : null}

        {user.perfil === 'Master' || user.perfil === 'Administrador' ? (
          <li
            className={`menu-item ${getMenuItemActive('/user-profile', false)}`}
          >
            <NavLink className="menu-link" to="/Usuario">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl(
                    '/media/svg/icons/Communication/Add-user.svg'
                  )}
                />
              </span>
              <span className="menu-text">Usuários</span>
            </NavLink>
          </li>
        ) : null}

        {/*end::1 Level*/}
      </ul>

      {/*
        <li
                  className={`menu-item ${getMenuItemActive('/user-profile', false)}`}
                >
                  <NavLink className="menu-link" to="/Usuario/Index">
                    <span className="svg-icon menu-icon">
                      <SVG
                        src={toAbsoluteUrl(
                          '/media/svg/icons/Communication/Add-user.svg'
                        )}
                      />
                    </span>
                    <span className="menu-text">Usuários</span>
                  </NavLink>
                </li>
        */}

      {/* end::Menu Nav */}
    </>
  )
}
