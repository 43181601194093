import React from 'react'

import { Dashboard1 } from './Dashboard1'

export function Dashboard() {
  /*
  const uiService = useHtmlClassService()


  const layoutProps = useMemo(() => {
    return {
      demo: objectPath.get(uiService.config, 'demo')
    }
  }, [uiService])*/
  return (
    <>
      <Dashboard1 />
    </>
  )
}
