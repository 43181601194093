import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import 'whatwg-fetch'
import Table from '../components/Table'

import { isMobile } from 'react-device-detect'

export default function Usuario() {
  const { user } = useSelector((state) => state.auth, shallowEqual)

  const tableTemplate = {
    apiURL: '/api/v1/usuario',
    getParams: {
      grupoempresa_id: user.grupoempresa,
      empresa_id: user.grupoempresa
    },
    postParams: {
      grupoempresa_id: user.grupoempresa,
      empresa_id: user.grupoempresa
    },
    DataGrid: {
      showBorders: true,
      repaintChangesOnly: true,
      onInitNewRow: (e) => {
        e.data.ativo = true
        e.data.perfil = 'Usuario'
      },
      onEditorPreparing: (e) => {
        if (e.parentType === 'dataRow' && e.dataField === 'senha') {
          e.editorOptions.mode = 'password'
          e.editorOptions.onFocusIn = function (arg) {
            arg.component.option('value', '')
          }
        }
      }
    },
    FilterRow: { visible: false },
    HeaderFilter: { visible: false },

    SearchPanel: { visible: true, width: 240, placeholder: 'Pesquisar...' },

    Paging: { defaultPageSize: 10 },

    Pager: {
      showPageSizeSelector: true,
      allowedPageSizes: [5, 10, 20],
      showInfo: true,
      infoText: 'Página {0} de {1} ({2} registros)'
    },

    Editing: {
      refreshMode: 'full',
      mode: 'popup',
      allowAdding: true,
      allowDeleting: true,
      allowUpdating: true,
      Texts: {
        editRow: 'Alterar',
        addRow: 'Incluir',
        deleteRow: 'Excluir',
        saveRowChanges: 'Salvar',
        cancelRowChanges: 'Cancelar'
      },
      Popup: {
        title: 'Cadastro de Usuários',
        showTitle: true,
        width: 700,
        height: 525
      }
    },
    Columns: [
      {
        dataField: 'id',
        dataType: 'number',
        caption: 'ID',
        visible: isMobile ? false : true,
        formItem: {
          visible: false
        },
        allowEditing: false
      },

      {
        dataField: 'nome',
        dataType: 'string',
        caption: 'Nome',
        visible: true,
        allowEditing: true,

        RequiredRule: {
          message: 'Preenchimento Obrigatório'
        },

        StringLengthRule: {
          message: 'Nome precisa ter 2 a 60 caracteres',
          min: 2,
          max: 60
        }
      },

      {
        dataField: 'sobrenome',
        dataType: 'string',
        caption: 'Sobrenome',
        visible: isMobile ? false : true,
        formItem: { visible: true },
        allowEditing: true,

        RequiredRule: {
          message: 'Preenchimento Obrigatório'
        },

        StringLengthRule: {
          message: 'Sobrenome precisa ter 2 a 60 caracteres',
          min: 2,
          max: 60
        }
      },

      {
        dataField: 'email',
        dataType: 'string',
        caption: 'E-mail',
        visible: true,
        allowEditing: true,

        RequiredRule: {
          message: 'Preenchimento Obrigatório'
        },

        EmailRule: {
          message: 'E-mail com formato incorreto'
        }
      },

      {
        dataField: 'perfil',
        dataType: 'string',
        caption: 'Perfil',
        visible: isMobile ? false : true,
        formItem: { visible: true },
        allowEditing: true,

        editorOptions: { placeholder: 'Selecione...', showClearButton: true },

        RequiredRule: {
          message: 'Preenchimento Obrigatório'
        },

        Lookup: {
          valueExpr: 'id',
          displayExpr: 'perfil',
          datasource: [
            { id: 'Administrador', perfil: 'Administrador' },
            user.perfil === 'Master'
              ? { id: 'Master', perfil: 'Master' }
              : null,
            { id: 'Usuario', perfil: 'Usuário' }
          ]
        }
      },
      {
        dataField: 'empresadefault_id',
        dataType: 'number',
        caption: 'Empresa Default',
        visible: isMobile ? false : true,
        formItem: { visible: true },
        allowEditing: true,

        editorOptions: { placeholder: 'Selecione...', showClearButton: true },

        RequiredRule: {
          message: 'Preenchimento Obrigatório'
        },

        Lookup: {
          key: 'id',
          loadMode: 'raw',
          url: '/api/v1/empresa',
          params: {
            grupoempresa_id: user.grupoempresa
          },
          valueExpr: 'id',
          displayExpr: 'nome'
        }
      },
      {
        dataField: 'senha',
        dataType: 'string',
        caption: 'Senha',
        allowEditing: true,
        visible: false,
        formItem: { visible: true },

        RequiredRule: {
          message: 'Preenchimento Obrigatório'
        },

        StringLengthRule: {
          message: 'Sobrenome precisa ter 6 a 60 caracteres',
          min: 6,
          max: 60
        }
      },
      {
        dataField: 'ativo',
        dataType: 'boolean',
        caption: 'Ativo',
        visible: isMobile ? false : true,
        formItem: { visible: true },
        allowEditing: true
      }
    ]
  }

  return (
    <React.Fragment>
      <Table tableTemplate={tableTemplate} />
    </React.Fragment>
  )
}
