import React from 'react'
import FileUploadForm from '../components/FileUploadForm'
import { useImportar } from '../context/Importar'
import AppAlert from '../components/AppAlert'

export default function Importar() {
  const { showAlert, alertMessage, alertVariant } = useImportar()

  return (
    <>
      <div id="formUploadContent" className="formUploadContent">
        <AppAlert
          showAlert={showAlert}
          alertMessage={alertMessage}
          alertVariant={alertVariant}
        ></AppAlert>
        <FileUploadForm></FileUploadForm>
      </div>
    </>
  )
}
