import React, { createContext, useContext, useState } from 'react'

const AppContext = createContext()

export default function AppProvider({ children }) {
  const [recebimento, setRecebimento] = useState({})
  const [pagamento, setPagamento] = useState({})

  return (
    <AppContext.Provider
      value={{
        recebimento,
        setRecebimento,
        pagamento,
        setPagamento
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export function useApp() {
  const context = useContext(AppContext)
  if (!context) throw new Error('useApp não pode ser utilizado')
  const { recebimento, setRecebimento, pagamento, setPagamento } = context

  return {
    recebimento,
    setRecebimento,
    pagamento,
    setPagamento
  }
}
