import React, { useRef, useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { Typography, TextField } from '@material-ui/core'
import FileUploader from 'devextreme-react/file-uploader'
import Button from 'devextreme-react/button'
import axios from 'axios'
import { useImportar } from '../context/Importar'
import './FileUploadForm.css'

export default function Index() {
  const formElement = React.createRef()
  const fileuploader = useRef(null)
  const { user } = useSelector((state) => state.auth, shallowEqual)
  const [excecoes, setExcecoes] = useState('')
  const [loading, setLoading] = useState(false)

  const enableLoading = () => {
    setLoading(true)
  }

  const disableLoading = () => {
    setLoading(false)
  }

  useEffect(
    () => {
      fileuploader.current.instance.reset()
      setShowAlert(false)

      axios
        .get('/api/v1/default', {
          params: {
            grupoempresa_id: user.grupoempresa,
            nome: 'excecoes'
          }
        })
        .then((res) => {
          setExcecoes(res.data.data[0].valor)
        })
        .catch((err) => {})
    },
    // eslint-disable-next-line
    [user]
  )

  const {
    uploadURL,
    setShowAlert,
    setAlertMessage,
    setAlertVariant
  } = useImportar()

  function showAlert(mensagem, variant) {
    setAlertMessage(mensagem)
    setAlertVariant(variant)
    setShowAlert(true)
  }

  function handleValueChanged() {
    setShowAlert(false)
  }

  function onClick() {
    if (fileuploader.current.instance._files.length !== 1) {
      showAlert('Selecione o arquivo', 'warning')
      return
    }

    const data = new FormData(formElement.current)

    const config = {
      headers: { 'content-type': 'multipart/form-data' }
    }

    enableLoading()

    axios
      .post(uploadURL, data, config)
      .then((res) => {
        disableLoading()

        const data = res.data || {
          data: {},
          ok: false,
          message:
            'Ocorreu um erro ao importar o excel, por favor, verifique se o arquivo está correto [2]'
        }

        if (data.ok) {
          fileuploader.current.instance.reset()
          showAlert(data.message, 'primary')
        } else {
          showAlert(data.message, 'danger')
        }
      })
      .catch((err) => {
        disableLoading()
        showAlert('Erro ao importar balancete', 'danger')
        console.log(err)
      })
  }

  return (
    <form
      id="form"
      ref={formElement}
      method="post"
      action=""
      encType="multipart/form-data"
    >
      <div id="formupload-container">
        <Typography variant="h6" gutterBottom>
          Empresa: {user.empresanome}
        </Typography>

        <TextField
          id="importar-empresa"
          name="excecoes"
          label="Contas iniciadas com:"
          margin="normal"
          value={excecoes}
          onChange={(e) => setExcecoes(e.target.value)}
          InputLabelProps={{
            shrink: true
          }}
          disabled={loading}
        />
      </div>
      <div className="fileuploader-container">
        <input
          type="text"
          name="empresa"
          value={user.empresa}
          hidden
          readOnly
        />
        <input
          type="text"
          name="grupoempresa"
          value={user.grupoempresa}
          hidden
          readOnly
        />
        <FileUploader
          ref={fileuploader}
          labelText=""
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          uploadMode="useForm"
          uploadedMessage="Upload concluído"
          uploadFailedMessage="Upload falhou"
          uploadAbortedMessage="Upload cancelado"
          selectButtonText="Selecionar Arquivo"
          uploadButtonText="Importar"
          readyToUploadMessage="Pronto para Upload"
          invalidFileExtensionMessage="Arquivo precisa ser no formato .xlsx"
          invalidMaxFileSizeMessage="Tamanho do arquivo inválido"
          invalidMinFileSizeMessage="Tamanho do arquivo inválido"
          name="arquivo"
          onValueChanged={handleValueChanged}
          disabled={loading}
        />
      </div>
      <Button
        className="button"
        text="Importar"
        type="success"
        onClick={onClick}
        disabled={loading}
      />
    </form>
  )
}
