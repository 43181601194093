import React, { useEffect } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import 'whatwg-fetch'
import Table from '../components/Table'
import { useApp } from '../context/App'

import ExcelJS from 'exceljs'
import saveAs from 'file-saver'

import { exportDataGrid } from 'devextreme/excel_exporter'
import axios from 'axios'

import { isMobile } from 'react-device-detect'

export default function Operacao(props) {
  const { user } = useSelector((state) => state.auth, shallowEqual)
  const { recebimento, setRecebimento, pagamento, setPagamento } = useApp()

  useEffect(
    () => {
      setRecebimento({})
      setPagamento({})
    },
    // eslint-disable-next-line
    []
  )

  const tableTemplate = {
    apiURL: '/api/v1/operacao',
    getParams: {
      grupoempresa_id: user.grupoempresa,
      empresa_id: user.empresa,
      tipo: props.tipo
    },
    postParams: {
      grupoempresa_id: user.grupoempresa,
      empresa_id: user.empresa,
      tipo: props.tipo
    },
    DataGrid: {
      showBorders: true,
      repaintChangesOnly: true,
      onInitNewRow: (e) => {
        let lastdata = props.tipo === 'Recebimento' ? recebimento : pagamento

        e.data.tipo = props.tipo

        if (lastdata.data) {
          e.data.data = lastdata.data
        }

        if (lastdata.numero) {
          if (lastdata.numero.length > 0) {
            e.data.numero = `${lastdata.numero.split(' ')[0]} `
          }
        }

        e.data.valor = 0.0
        e.data.multa = 0.0
        e.data.juros = 0.0
        e.data.valortotal = 0.0
      },
      onEditorPreparing: (e) => {
        if (
          e.parentType === 'dataRow' &&
          (e.dataField === 'numero' || e.dataField === 'historico')
        ) {
          e.editorElement.classList.add('uppercase')
        }
      },
      onRowInserted: (e) => {
        setTimeout(function () {
          e.component.addRow()
        }, 500)
      },
      onRowInserting: (e) => {
        e.data.numero = e.data.numero.toUpperCase()
        e.data.historico = e.data.historico.toUpperCase()

        props.tipo === 'Recebimento'
          ? setRecebimento(e.data)
          : setPagamento(e.data)
      },
      onRowValidating: (e) => {
        if (e.isValid) {
          e.errorText = ''

          if (e.newData.hasOwnProperty('valor')) {
            if (!(e.newData.valor > 0)) {
              e.isValid = false
              e.errorText = 'Valor precisa ser maior do que zero'
              return
            }
          }
        }
      },
      onExporting(e) {
        e.cancel = true

        const workbook = new ExcelJS.Workbook()
        const worksheet = workbook.addWorksheet(
          props.tipo === 'Recebimento' ? 'Recebimentos' : 'Pagamentos'
        )

        axios
          .get(`/api/v1/planoconta?empresa_id=${user.empresa}`)
          .then((response) => {
            const planoConta = response.data.data

            let dados = []

            let operacao = {
              Conta: '',
              DtPgto: '',
              CtaDebito: 0,
              CtaCredito: 0,
              Valor: 0.0,
              CentroCusto: 1,
              CodigoHistorico: 1,
              Historico: ''
            }

            exportDataGrid({
              component: e.component,
              worksheet: worksheet,
              keepColumnWidths: false,
              topLeftCell: { row: 1, column: 1 },
              customizeCell: ({ gridCell, excelCell }) => {
                if (gridCell.rowType === 'data') {
                  switch (gridCell.column.dataField) {
                    case 'data':
                      operacao.DtPgto = gridCell.value
                      break
                    case 'contadebito_id':
                      const contadebito = planoConta.find(
                        (conta) => conta.id === parseInt(gridCell.value)
                      )

                      operacao.CtaDebito = parseInt(contadebito.reduzida)

                      if (props.tipo === 'Pagamento') {
                        operacao.Conta = contadebito.descricao
                      }
                      break
                    case 'contacredito_id':
                      const contacredito = planoConta.find(
                        (conta) => conta.id === parseInt(gridCell.value)
                      )

                      operacao.CtaCredito = parseInt(contacredito.reduzida)

                      if (props.tipo === 'Recebimento') {
                        operacao.Conta = contacredito.descricao
                      }
                      break
                    case 'valortotal':
                      operacao.Valor = parseFloat(gridCell.value) //.toFixed(2)
                      break
                    case 'historico':
                      operacao.Historico = gridCell.value

                      dados.push(operacao)

                      operacao = {
                        Conta: '',
                        DtPgto: '',
                        CtaDebito: 0,
                        CtaCredito: 0,
                        Valor: 0.0,
                        CentroCusto: 1,
                        CodigoHistorico: 1,
                        Historico: ''
                      }

                      break
                    default:
                      break
                  }
                }
              }
            }).then(() => {
              //console.log(dados)

              workbook.removeWorksheet(worksheet.id)

              const worksheetFinal = workbook.addWorksheet(
                props.tipo === 'Recebimento' ? 'Recebimentos' : 'Pagamentos'
              )

              worksheetFinal.columns = [
                { header: 'Conta', key: 'Conta', width: 50 },
                { header: 'Dt Pagto', key: 'DtPagto', width: 10 },
                { header: 'Cta Debito', key: 'CtaDebito', width: 12 },
                { header: 'Cta Credito', key: 'CtaCredito', width: 12 },
                { header: 'Valor', key: 'Valor', width: 10 },
                { header: 'Centro de Custo', key: 'CentroCusto', width: 15 },
                {
                  header: 'Código de Historico',
                  key: 'CodigoHistorico',
                  width: 18
                },
                { header: 'Histórico', key: 'Historico', width: 50 }
              ]

              dados.forEach((value, index) => {
                const row = worksheetFinal.getRow(index + 2)

                Object.entries(value).forEach((cell, cellIndex) => {
                  row.getCell(cellIndex + 1).value = cell[1]

                  if (cellIndex + 1 === 5) {
                    row.getCell(cellIndex + 1).numFmt =
                      '"R$" #,##0.00;[Red]-"R$" #,##0.00'
                  }
                })
              })

              const headers = ['A1', 'B1', 'C1', 'D1', 'E1', 'F1', 'G1', 'H1']

              headers.forEach((value) => {
                worksheetFinal.getCell(value).fill = {
                  type: 'pattern',
                  pattern: 'solid',
                  fgColor: { argb: '4662cc' }
                }

                worksheetFinal.getCell(value).font = {
                  color: { argb: 'FFFFFF' }
                }
              })

              workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(
                  new Blob([buffer], { type: 'application/octet-stream' }),
                  props.tipo === 'Recebimento'
                    ? 'Recebimentos.xlsx'
                    : 'Pagamentos.xlsx'
                )
              })
            })
          })
          .catch((error) => {})
      }
    },
    Export: { enabled: user.perfil === 'Master' ? true : false },
    FilterRow: { visible: false },
    HeaderFilter: { visible: true },

    SearchPanel: { visible: true, width: 240, placeholder: 'Pesquisar...' },

    Paging: { defaultPageSize: 10 },

    Pager: {
      showPageSizeSelector: true,
      allowedPageSizes: [5, 10, 20],
      showInfo: true,
      infoText: 'Página {0} de {1} ({2} registros)'
    },

    Editing: {
      refreshMode: 'full',
      mode: 'popup',
      allowAdding: true,
      allowDeleting: true,
      allowUpdating: true,
      Texts: {
        editRow: 'Alterar',
        addRow: 'Incluir',
        deleteRow: 'Cancelar',
        saveRowChanges: 'Salvar',
        cancelRowChanges: 'Cancelar'
      },
      Popup: {
        title:
          props.tipo === 'Recebimento'
            ? 'Cadastro de Recebimento'
            : 'Cadastro de Pagamento',
        showTitle: true,
        width: 700,
        height: 600
      }
    },
    Columns: [
      {
        dataField: 'id',
        dataType: 'number',
        caption: 'ID',
        allowEditing: false,
        visible: false,
        formItem: {
          visible: false
        }
      },

      {
        dataField: 'tipo',
        dataType: 'string',
        caption: 'Tipo',
        allowEditing: false,
        visible: false,
        formItem: {
          visible: false
        }
      },

      {
        dataField: 'numero',
        dataType: 'string',
        caption: 'Documento',
        allowEditing: true,

        visible: isMobile ? false : true,
        formItem: {
          visible: true
        },

        RequiredRule: {
          message: 'Preenchimento Obrigatório'
        },

        StringLengthRule: {
          message: 'Documento precisa ter menos que 200 caracteres',
          max: 200
        },

        setCellValue: (newData, value, currentRowData) => {
          newData.numero = value.toString()

          newData.historico = ''

          if (newData.numero) {
            if (newData.numero.length > 0) {
              newData.historico =
                props.tipo === 'Recebimento'
                  ? 'RECEBIMENTO ' + newData.numero.toString()
                  : 'PAGAMENTO ' + newData.numero.toString()
            }
          }
        }
      },

      {
        dataField: 'data',
        dataType: 'date',
        caption: 'Data',
        allowEditing: true,
        visible: true,
        formItem: {
          visible: true
        },
        format: 'dd/MM/yyyy',

        editorOptions: {
          displayFormat: 'dd/MM/yyyy',
          useMaskBehavior: true,
          type: 'date'
        },

        RequiredRule: {
          message: 'Preenchimento Obrigatório'
        }
      },

      {
        dataField: 'contadebito_id',
        dataType: 'number',
        caption: props.tipo === 'Recebimento' ? 'Banco / Caixa' : 'Despesa',
        allowEditing: true,
        visible: isMobile ? false : true,
        formItem: {
          visible: true
        },

        RequiredRule: {
          message: 'Preenchimento Obrigatório'
        },

        editorOptions: { placeholder: 'Selecione...', showClearButton: true },

        Lookup: {
          key: 'id',
          loadMode: 'raw',
          url: '/api/v1/planoconta',
          params: {
            grupoempresa_id: user.grupoempresa,
            empresa_id: user.empresa,
            contas:
              props.tipo === 'Recebimento'
                ? ['110101', '110102']
                : [
                    '1105',
                    '2101',
                    '2103',
                    '2104',
                    '410101',
                    '410203',
                    '410301',
                    '410701',
                    '410601',
                    '410501'
                  ],
            order: [['descricao', 'ASC']]
          },
          valueExpr: 'id',
          displayExpr: 'descricao',
          placeholder: 'Selecione'
        }
      },

      {
        dataField: 'contacredito_id',
        dataType: 'number',
        caption: props.tipo === 'Recebimento' ? 'Pagador' : 'Banco / Caixa',
        allowEditing: true,
        visible: isMobile ? false : true,
        formItem: {
          visible: true
        },

        RequiredRule: {
          message: 'Preenchimento Obrigatório'
        },

        editorOptions: { placeholder: 'Selecione...', showClearButton: true },

        Lookup: {
          key: 'id',
          loadMode: 'raw',
          url: '/api/v1/planoconta',
          params: {
            grupoempresa_id: user.grupoempresa,
            empresa_id: user.empresa,
            contas:
              props.tipo === 'Recebimento' ? ['110201'] : ['110101', '110102'],
            order: [['descricao', 'ASC']]
          },
          valueExpr: 'id',
          displayExpr: 'descricao'
        }
      },

      /*
      {
        dataField: 'banco_id',
        dataType: 'number',
        caption: 'Banco',
        allowEditing: true,

        editorOptions: { placeholder: 'Selecione...', showClearButton: true },

        Lookup: {
          key: 'id',
          loadMode: 'raw',
          url: '/api/v1/banco',
          params: {
            grupoempresa_id: user.grupoempresa,
            empresa_id: user.empresa
          },
          valueExpr: 'id',
          displayExpr: 'nome'
        }
      },

      {
        dataField: 'centroresultado_id',
        dataType: 'number',
        caption: 'CR',
        allowEditing: true,

        editorOptions: { placeholder: 'Selecione...', showClearButton: true },

        Lookup: {
          key: 'id',
          loadMode: 'raw',
          url: '/api/v1/centroresultado',
          params: {
            grupoempresa_id: user.grupoempresa,
            empresa_id: user.empresa
          },
          valueExpr: 'id',
          displayExpr: 'descricao'
        }
      },
      */

      {
        dataField: 'valor',
        dataType: 'number',
        caption: 'Valor',
        allowEditing: true,
        visible: isMobile ? false : true,
        formItem: {
          visible: true
        },

        Format: { type: 'currency', precision: 2 },
        format: 'currency',
        editorOptions: { format: 'R$ #,##0.##' },

        RequiredRule: {
          message: 'Preenchimento Obrigatório'
        },
        setCellValue: (newData, value, currentRowData) => {
          newData.valor = parseFloat(value)
          newData.valortotal =
            parseFloat(value ? value : 0) +
            parseFloat(currentRowData.multa ? currentRowData.multa : 0) +
            parseFloat(currentRowData.juros ? currentRowData.juros : 0)
        }
      },

      {
        dataField: 'multa',
        dataType: 'number',
        caption: 'Multa',
        allowEditing: true,
        visible: isMobile ? false : true,
        formItem: {
          visible: true
        },

        Format: { type: 'currency', precision: 2 },
        format: 'currency',
        editorOptions: { format: 'R$ #,##0.##' },

        setCellValue: (newData, value, currentRowData) => {
          newData.multa = parseFloat(value)
          newData.valortotal =
            parseFloat(value ? value : 0) +
            parseFloat(currentRowData.valor ? currentRowData.valor : 0) +
            parseFloat(currentRowData.juros ? currentRowData.juros : 0)
        }
      },

      {
        dataField: 'juros',
        dataType: 'number',
        caption: 'Juros',
        allowEditing: true,
        visible: isMobile ? false : true,
        formItem: {
          visible: true
        },

        Format: { type: 'currency', precision: 2 },
        format: 'currency',
        editorOptions: { format: 'R$ #,##0.##' },

        setCellValue: (newData, value, currentRowData) => {
          newData.juros = parseFloat(value)
          newData.valortotal =
            parseFloat(value ? value : 0) +
            parseFloat(currentRowData.multa ? currentRowData.multa : 0) +
            parseFloat(currentRowData.valor ? currentRowData.valor : 0)
        }
      },

      {
        dataField: 'valortotal',
        dataType: 'number',
        caption: 'Valor Total',
        allowEditing: false,
        visible: true,
        formItem: {
          visible: true
        },

        Format: { type: 'currency', precision: 2 },
        format: 'currency',
        editorOptions: { format: 'R$ #,##0.##' }
      },

      {
        dataField: 'historico',
        dataType: 'string',
        caption: 'Histórico',
        allowEditing: true,
        visible: true,
        formItem: {
          visible: true
        },

        RequiredRule: {
          message: 'Preenchimento Obrigatório'
        }
      }
      /*
      {
        dataField: 'status',
        dataType: 'string',
        caption: 'Status',
        allowEditing: false,

        Lookup: {
          valueExpr: 'status',
          displayExpr: 'status',
          datasource: [{ status: 'Cancelado' }, { status: 'Confirmado' }]
        },

        RequiredRule: {
          message: 'Preenchimento Obrigatório'
        }
      }*/
    ]
  }

  return (
    <React.Fragment>
      <Table tableTemplate={tableTemplate} />
    </React.Fragment>
  )
}
