import React, { Suspense } from 'react'
import { Redirect, Switch } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'
import { LayoutSplashScreen, ContentRoute } from '../_metronic/layout'
import { DashboardPage } from './pages/DashboardPage'
import GrupoEmpresa from './pages/GrupoEmpresa'
import Banco from './pages/Banco'
import Usuario from './pages/Usuario'
import CentroResultado from './pages/CentroResultado'
import PlanoConta from './pages/PlanoConta'
import Importar from './pages/Importar'
import AppProvider from './context/App'
import ImportarProvider from './context/Importar'
import Empresa from './pages/Empresa'
import Operacao from './pages/Operacao'

export default function BasePage() {
  let { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null
    }),
    shallowEqual
  )

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {!isAuthorized ? (
          <Redirect to="/logout" />
        ) : (
          <Redirect exact from="/" to="/dashboard" />
        )}

        <AppProvider>
          <ContentRoute path="/dashboard" component={DashboardPage} />
          <ContentRoute path="/GrupoEmpresa" component={GrupoEmpresa} />
          <ContentRoute path="/Banco" component={Banco} />
          <ContentRoute path="/CentroResultado" component={CentroResultado} />
          <ContentRoute path="/PlanoConta" component={PlanoConta} />
          <ContentRoute path="/Empresa" component={Empresa} />
          <ContentRoute path="/Usuario" component={Usuario} />
          <ContentRoute
            path="/Pagamentos"
            component={Operacao}
            tipo="Pagamento"
          />
          <ContentRoute
            path="/Recebimentos"
            component={Operacao}
            tipo="Recebimento"
          />

          <ImportarProvider>
            <ContentRoute path="/Importar" component={Importar} />
          </ImportarProvider>
          {/*
        <ContentRoute path="/my-page" component={MyPage} />
        <Route path="/google-material" component={GoogleMaterialPage} />
        <Route path="/react-bootstrap" component={ReactBootstrapPage} />
        <Route path="/e-commerce" component={ECommercePage} />
        <Route path="/user-profile" component={UserProfilepage} />
        */}
        </AppProvider>
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  )
}
