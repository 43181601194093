import React from 'react'
import axios from 'axios'
import { encodeURI } from '../util/util'

import {
  DataGrid,
  Column,
  Editing,
  FilterRow,
  HeaderFilter,
  Paging,
  Pager,
  SearchPanel,
  Texts,
  RequiredRule,
  StringLengthRule,
  Popup,
  AsyncRule,
  FormItem,
  EmailRule,
  Lookup,
  Format,
  Export
} from 'devextreme-react/data-grid'

import { locale, loadMessages } from 'devextreme/localization'

import CustomStore from 'devextreme/data/custom_store'
import 'whatwg-fetch'
import config from 'devextreme/core/config'

import ptMessages from 'devextreme/localization/messages/pt.json'

import './Table.css'

export default function Table(props) {
  const state = {
    tableTemplate: props.tableTemplate,
    apiData: new CustomStore({
      key: props.tableTemplate.id || 'id',
      load: () =>
        sendRequest(props.tableTemplate.apiURL, 'GET', {
          values: JSON.stringify(
            props.tableTemplate.getParams ? props.tableTemplate.getParams : {}
          )
        }),
      insert: (values) =>
        sendRequest(props.tableTemplate.apiURL, 'POST', {
          values: JSON.stringify(
            Object.assign(
              values,
              props.tableTemplate.postParams
                ? props.tableTemplate.postParams
                : {}
            )
          )
        })
          .then((result) => {})
          .catch((error) => {
            throw error
          }),
      update: (key, values) =>
        sendRequest(props.tableTemplate.apiURL, 'PUT', {
          key: key,
          values: JSON.stringify(
            Object.assign(
              values,
              props.tableTemplate.postParams
                ? props.tableTemplate.postParams
                : {}
            )
          )
        }),
      remove: (key) =>
        sendRequest(props.tableTemplate.apiURL, 'DELETE', {
          key: key
        })
    })
  }

  loadMessages(ptMessages)
  locale('pt-BR')

  config({
    defaultCurrency: 'BRL'
  })

  function endPromise(resolve, reject, apiData) {
    if (apiData) {
      if (apiData.ok) {
        resolve(apiData.data)
      } else {
        reject(apiData.Message)
      }
    } else {
      reject('Ocorreu um erro na operação, entre em contato com o suporte.')
    }
  }

  function sendRequest(url, method, data) {
    const promise = new Promise((resolve, reject) => {
      method = method || 'GET'
      data = data || {}

      if (method === 'GET') {
        if (data.hasOwnProperty('values')) {
          data = JSON.parse(data.values)
        }
      }

      const params = encodeURI(data)

      if (method === 'GET') {
        if (params) {
          url = url + '?' + params
        }

        return axios
          .get(url)
          .then(function (response) {
            endPromise(resolve, reject, response.data)
          })
          .catch(function (error) {})
      }

      if (method === 'PUT') {
        return axios
          .put(url, params)
          .then(function (response) {
            endPromise(resolve, reject, response.data)
          })
          .catch(function (error) {})
      }

      if (method === 'DELETE') {
        return axios
          .delete(url, { data: data })
          .then(function (response) {
            endPromise(resolve, reject, response.data)
          })
          .catch(function (error) {})
      }

      if (method === 'POST') {
        return axios
          .post(url, params)
          .then(function (response) {
            endPromise(resolve, reject, response.data)
          })
          .catch(function (error) {})
      }
    })

    return promise
  }

  const { apiData, tableTemplate } = state

  return (
    <React.Fragment>
      <DataGrid
        id="grid"
        showBorders={tableTemplate.DataGrid.showBorders}
        dataSource={apiData}
        repaintChangesOnly={tableTemplate.DataGrid.repaintChangesOnly}
        noDataText={
          tableTemplate.DataGrid.noDataText || 'Nenhum registro cadastrado'
        }
        onRowInserted={
          tableTemplate.DataGrid.onRowInserted
            ? tableTemplate.DataGrid.onRowInserted
            : null
        }
        onRowInserting={
          tableTemplate.DataGrid.onRowInserting
            ? tableTemplate.DataGrid.onRowInserting
            : null
        }
        onRowUpdated={
          tableTemplate.DataGrid.onRowUpdated
            ? tableTemplate.DataGrid.onRowUpdated
            : null
        }
        onInitNewRow={
          tableTemplate.DataGrid.onInitNewRow
            ? tableTemplate.DataGrid.onInitNewRow
            : null
        }
        onEditorPreparing={
          tableTemplate.DataGrid.onEditorPreparing
            ? tableTemplate.DataGrid.onEditorPreparing
            : null
        }
        onRowValidating={
          tableTemplate.DataGrid.onRowValidating
            ? tableTemplate.DataGrid.onRowValidating
            : null
        }
        onExporting={
          tableTemplate.DataGrid.onExporting
            ? tableTemplate.DataGrid.onExporting
            : null
        }
      >
        {tableTemplate.Export ? (
          <Export enabled={tableTemplate.Export.enabled}></Export>
        ) : null}
        <FilterRow visible={tableTemplate.FilterRow.visible} />
        <HeaderFilter visible={tableTemplate.HeaderFilter.visible} />
        <SearchPanel
          visible={tableTemplate.SearchPanel.visible}
          width={tableTemplate.SearchPanel.width}
          placeholder={tableTemplate.SearchPanel.placeholder}
        />

        <Paging defaultPageSize={tableTemplate.Paging.defaultPageSize} />
        <Pager
          showPageSizeSelector={tableTemplate.Pager.showPageSizeSelector}
          allowedPageSizes={tableTemplate.Pager.allowedPageSizes}
          showInfo={tableTemplate.Pager.showInfo}
          infoText={tableTemplate.Pager.infoText}
        />

        <Editing
          refreshMode={tableTemplate.Editing.refreshMode}
          mode={tableTemplate.Editing.mode}
          allowAdding={tableTemplate.Editing.allowAdding}
          allowDeleting={tableTemplate.Editing.allowDeleting}
          allowUpdating={tableTemplate.Editing.allowUpdating}
        >
          <Texts
            editRow={tableTemplate.Editing.Texts.editRow}
            addRow={tableTemplate.Editing.Texts.addRow}
            deleteRow={tableTemplate.Editing.Texts.deleteRow}
            saveRowChanges={tableTemplate.Editing.Texts.saveRowChanges}
            cancelRowChanges={tableTemplate.Editing.Texts.cancelRowChanges}
            confirmDeleteMessage={
              tableTemplate.Editing.Texts.confirmDeleteMessage
            }
          />
          {tableTemplate.Editing.Popup ? (
            <Popup
              title={tableTemplate.Editing.Popup.title}
              showTitle={tableTemplate.Editing.Popup.showTitle}
              width={tableTemplate.Editing.Popup.width}
              height={tableTemplate.Editing.Popup.height}
            ></Popup>
          ) : null}
        </Editing>

        {tableTemplate.Columns.map((column) => (
          <Column
            dataField={column.dataField}
            caption={column.caption}
            dataType={column.dataType}
            allowEditing={column.allowEditing}
            key={column.dataField}
            customizeText={column.customizeText ? column.customizeText : null}
            editorOptions={column.editorOptions}
            showEditorAlways={column.showEditorAlways}
            visible={column.visible}
            formItem={column.formItem ? column.formItem : null}
            calculateCellValue={column.calculateCellValue}
            setCellValue={column.setCellValue}
            format={column.format}
            precision={column.precision}
          >
            {column.Format ? (
              <Format
                type={column.Format.type}
                precision={column.Format.precision}
              />
            ) : null}

            {column.formItem ? (
              <FormItem visible={column.formItem.visible} />
            ) : null}

            {column.Lookup ? (
              <Lookup
                dataSource={
                  column.Lookup.datasource
                    ? column.Lookup.datasource
                    : new CustomStore({
                        key: column.Lookup.key,
                        loadMode: column.Lookup.loadMode,
                        load: () =>
                          sendRequest(column.Lookup.url, 'GET', {
                            values: JSON.stringify(column.Lookup.params)
                          })
                      })
                }
                valueExpr={column.Lookup.valueExpr}
                displayExpr={column.Lookup.displayExpr}
              ></Lookup>
            ) : null}

            {column.RequiredRule ? (
              <RequiredRule message={column.RequiredRule.message} />
            ) : null}

            {column.EmailRule ? (
              <EmailRule message={column.EmailRule.message} />
            ) : null}

            {column.StringLengthRule ? (
              <StringLengthRule
                message={column.StringLengthRule.message}
                min={column.StringLengthRule.min}
                max={column.StringLengthRule.max}
              />
            ) : null}

            {column.AsyncRule ? (
              <AsyncRule
                message={column.AsyncRule.message}
                validationCallback={column.AsyncRule.validationCallback}
              />
            ) : null}
          </Column>
        ))}
      </DataGrid>
    </React.Fragment>
  )
}
