import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'

import { Dashboard } from '../../_metronic/_partials'

export function DashboardPage() {
  // eslint-disable-next-line
  const { user } = useSelector((state) => state.auth, shallowEqual)

  return <Dashboard />
}
