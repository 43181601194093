import React from 'react'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import 'whatwg-fetch'
import Table from '../components/Table'
import { cnpjValidation } from '../util/util'
import axios from 'axios'
import * as auth from '../modules/Auth'

import { isMobile } from 'react-device-detect'

export default function Empresa() {
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.auth, shallowEqual)

  function getEmpresas() {
    axios
      .get(
        `/api/v1/auth/empresa?usuario_id=${user.id}&grupoempresa_id=${user.grupoempresa}`
      )
      .then(function (response) {
        const newUser = Object.assign({}, user)

        newUser.empresas = response.data
          ? response.data.empresas
            ? response.data.empresas
            : []
          : []

        const updatedUser = Object.assign(newUser, {})

        dispatch(auth.actions.setUser(updatedUser))
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const tableTemplate = {
    apiURL: '/api/v1/empresa',
    getParams: {
      grupoempresa_id: user.grupoempresa
    },
    postParams: {},
    DataGrid: {
      showBorders: true,
      repaintChangesOnly: true,
      onRowInserted: (e) => {
        getEmpresas()
      },
      onRowUpdated: (e) => {
        getEmpresas()
      },
      onInitNewRow: (e) => {
        e.data.ativo = true
      }
    },
    FilterRow: { visible: false },
    HeaderFilter: { visible: false },

    SearchPanel: { visible: true, width: 240, placeholder: 'Pesquisar...' },

    Paging: { defaultPageSize: 10 },

    Pager: {
      showPageSizeSelector: true,
      allowedPageSizes: [5, 10, 20],
      showInfo: true,
      infoText: 'Página {0} de {1} ({2} registros)'
    },

    Editing: {
      refreshMode: 'full',
      mode: 'popup',
      allowAdding: true,
      allowDeleting: true,
      allowUpdating: true,
      Texts: {
        editRow: 'Alterar',
        addRow: 'Incluir',
        deleteRow: 'Excluir',
        saveRowChanges: 'Salvar',
        cancelRowChanges: 'Cancelar'
      },
      Popup: {
        title: 'Cadastro de Empresas',
        showTitle: true,
        width: 700,
        height: 525
      }
    },
    Columns: [
      {
        dataField: 'id',
        dataType: 'number',
        caption: 'ID',
        visible: isMobile ? false : true,
        formItem: {
          visible: false
        },
        allowEditing: false
      },
      {
        dataField: 'nome',
        dataType: 'string',
        caption: 'Nome',
        allowEditing: true,

        RequiredRule: {
          message: 'Preenchimento Obrigatório'
        },

        StringLengthRule: {
          message: 'Nome precisa ter 2 a 100 caracteres',
          min: 2,
          max: 60
        }
      },
      {
        dataField: 'cnpj',
        dataType: 'string',
        caption: 'CNPJ',
        allowEditing: true,

        RequiredRule: {
          message: 'Preenchimento Obrigatório'
        },

        StringLengthRule: {
          message: 'CNPJ precisa ter 14 caracteres',
          min: 14,
          max: 14
        },

        customizeText: null,

        editorOptions: { mask: '00.000.000/0000-00' },
        showEditorAlways: true,

        AsyncRule: {
          message: 'CNPJ Inválido',
          validationCallback: (params) => {
            return new Promise((resolve) => {
              resolve(cnpjValidation(params.value))
            })
          }
        }
      },
      {
        dataField: 'grupoempresa_id',
        dataType: 'number',
        caption: 'Grupo Empresarial',
        visible: isMobile ? false : true,
        formItem: { visible: true },
        allowEditing: true,

        editorOptions: { placeholder: 'Selecione...', showClearButton: true },

        RequiredRule: {
          message: 'Preenchimento Obrigatório'
        },

        Lookup: {
          key: 'id',
          loadMode: 'raw',
          url: '/api/v1/grupoempresa',
          params: {
            grupoempresa_id: user.grupoempresa
          },
          valueExpr: 'id',
          displayExpr: 'nome'
        }
      },
      {
        dataField: 'ativo',
        dataType: 'boolean',
        caption: 'Ativo',
        visible: isMobile ? false : true,
        formItem: { visible: true },
        allowEditing: true
      }
    ]
  }

  return (
    <React.Fragment>
      <Table tableTemplate={tableTemplate} />
    </React.Fragment>
  )
}
