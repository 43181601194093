import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import 'whatwg-fetch'
import Table from '../components/Table'

import { isMobile } from 'react-device-detect'

export default function GrupoEmpresa() {
  // eslint-disable-next-line
  const { user } = useSelector((state) => state.auth, shallowEqual)

  const tableTemplate = {
    apiURL: '/api/v1/grupoempresa',
    getParams: {},
    postParams: {},
    DataGrid: {
      showBorders: true,
      repaintChangesOnly: true,
      onInitNewRow: (e) => {
        e.data.ativo = true
      }
    },
    FilterRow: { visible: false },
    HeaderFilter: { visible: false },

    SearchPanel: { visible: true, width: 240, placeholder: 'Pesquisar...' },

    Paging: { defaultPageSize: 10 },

    Pager: {
      showPageSizeSelector: true,
      allowedPageSizes: [5, 10, 20],
      showInfo: true,
      infoText: 'Página {0} de {1} ({2} registros)'
    },

    Editing: {
      refreshMode: 'full',
      mode: 'popup',
      allowAdding: true,
      allowDeleting: true,
      allowUpdating: true,
      Texts: {
        editRow: 'Alterar',
        addRow: 'Incluir',
        deleteRow: 'Excluir',
        saveRowChanges: 'Salvar',
        cancelRowChanges: 'Cancelar'
      },
      Popup: {
        title: 'Cadastro de Grupo Empresarial',
        showTitle: true,
        width: 700,
        height: 525
      }
    },
    Columns: [
      {
        dataField: 'id',
        dataType: 'number',
        caption: 'ID',
        visible: isMobile ? false : true,
        formItem: {
          visible: false
        },
        allowEditing: false
      },

      {
        dataField: 'nome',
        dataType: 'string',
        caption: 'Nome',
        allowEditing: true,

        RequiredRule: {
          message: 'Preenchimento Obrigatório'
        },

        StringLengthRule: {
          message: 'Nome precisa ter 2 a 60 caracteres',
          min: 2,
          max: 60
        }
      },

      {
        dataField: 'ativo',
        dataType: 'boolean',
        caption: 'Ativo',
        visible: isMobile ? false : true,
        formItem: { visible: true },
        allowEditing: true
      }
    ]
  }

  return (
    <React.Fragment>
      <Table tableTemplate={tableTemplate} />
    </React.Fragment>
  )
}
